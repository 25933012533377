@import 'src/style/flexbox';
@import url('https://fonts.googleapis.com/css?family=BenchNine:700|Open+Sans:300,700');

.app {
  font-family: 'Helvetica Neue', 'Arial', sans-serif;

  h2 {
    font-family: 'BenchNine', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;

		margin-bottom: 20px;

		font-weight: 700;
		color: #555;
	}

	p {
		color: #BBB;
		line-height: 1.85rem;
  }

  .mainSection {
    margin: 150px 0;
  }
}