@import 'src/style/flexbox';

.footer {
	background: #212121;

	margin: 0 !important;
	padding: 100px 0;

	p {
		font-family: 'Open Sans', sans-serif;
		font-size: 0.7rem;
		line-height: 1.3rem !important;

		a {
			color: #BBB !important;
		}

		span {
			color: #FFF;
			font-weight: 700;
			text-transform: uppercase;
			line-height: 2rem !important;
		}
	}
}