@import 'src/style/flexbox';

.about {
	img {
		margin-bottom: 40px;
	}
	
	h3 {
		margin: 40px 0 15px 0;

		font-size: 0.9rem;
		color: #555;
		font-weight: 700;
		letter-spacing: 0.05rem;
		text-transform: uppercase;

		span {
			font-size: 0.8rem;
			color: #999 !important;
			font-weight: 400 !important;
			text-transform: none !important;
		}
	}

	.caption {
		margin-bottom: 0;

		font-size: 0.9rem;
		color: #555;
		line-height: 1.5rem;
	}
}