@mixin user-select( $val ) {
  -webkit-user-select: $val;
  -moz-user-select: $val;
  -ms-user-select: $val;

  -o-user-select: $val;
  user-select: $val;
}

@mixin transform( $transforms ) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin transition( $prop : all, $time: 0.5s, $easing : linear, $delay: 0s ) {
  -webkit-transition: $prop $time $easing $delay;
  -moz-transition: $prop $time $easing $delay;
  -o-transition: $prop $time $easing $delay;
  transition: $prop $time $easing $delay;
}

@mixin transition-transform($duration, $timing, $delay:0s, $preface:webkit moz ms o spec){
  @each $browser in $preface {
    @if( $browser == spec) {
      transition: transform $duration $timing $delay;
    } @else {
      -#{$browser}-transition: -#{$browser}-transform $duration $timing $delay;
    }
  }
}

@mixin rotate( $deg ) {
  @include transform( rotate( #{$deg}deg ) );
}

// scale
@mixin scale($scale) {
   @include transform( scale( $scale ) );
}
// translate
@mixin translate( $x, $y ) {
   @include transform( translate( $x, $y ) );
}
// translateX
@mixin translateX( $x ) {
   @include transform( translateX( $x ) );
}
// translateY
@mixin translateY( $y ) {
   @include transform( translateY( $y ) );
}
// skew
@mixin skew($x, $y) {
   @include transform( skew( #{$x}deg, #{$y}deg ) );
}

@mixin scale3d( $x, $y, $z ) {
  @include transform( scale3d( $x, $y, $z ) );
}
//transform origin
@mixin transform-origin( $origin ) {
  -moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin blur($blur:5px) {
	-webkit-filter: blur($blur);
  -moz-filter: blur($blur);
  -o-filter: blur($blur);
  -ms-filter: blur($blur);
  filter: blur($blur);
}

@mixin background-image-cover( $img, $x, $y ) {
  background: url( $img ) no-repeat $x $y;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin box-sizing( $type: border-box ) {
  -webkit-box-sizing: $type;
  -moz-box-sizing: $type;
  -o-box-sizing: $type;
  -ms-box-sizing: $type;
  box-sizing: $type;
}

@mixin draggable( $val ) {
  -webkit-user-drag: $val;
  -khtml-user-drag: $val;
  -moz-user-drag: $val;
  -o-user-drag: $val;
  user-drag: $val;
}

@mixin center() {
  position: relative;
  top: 50%;
  left: 50%;
  @include translate( -50%, -50% );
}

@mixin linear-ease( $time: 500ms ) {
	-webkit-transition: all $time cubic-bezier(0.250, 0.250, 0.750, 0.750);
	-moz-transition: all $time cubic-bezier(0.250, 0.250, 0.750, 0.750);
	-o-transition: all $time cubic-bezier(0.250, 0.250, 0.750, 0.750);
	transition: all $time cubic-bezier(0.250, 0.250, 0.750, 0.750);
}

@mixin ease-out-circ( $time: 500ms, $prop: all ) {
  -webkit-transition: $prop $time cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: $prop $time cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: $prop $time cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: $prop $time cubic-bezier(0.215, 0.61, 0.355, 1);
}

@mixin box-shadow( $top, $left, $blur, $spread, $color, $inset: false ) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $spread $color;
    -moz-box-shadow:inset $top $left $blur $spread $color;
    -ms-box-shadow:inset $top $left $blur $spread $color;
    -o-box-shadow:inset $top $left $blur $spread $color;
    box-shadow:inset $top $left $blur $spread $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $spread $color;
    -moz-box-shadow: $top $left $blur $spread $color;
    -ms-box-shadow: $top $left $blur $spread $color;
    -o-shadow: $top $left $blur $spread $color;
    box-shadow: $top $left $blur $spread $color;
  }
}

@mixin border-radius( $radius ) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
}

@mixin animation-fill-mode( $dir ) {
  -webkit-animation-fill-mode: $dir;
  -moz-animation-fill-mode: $dir;
  -ms-animation-fill-mode: $dir;
  -o-animation-fill-mode: $dir;
  animation-fill-mode: $dir;
}

@mixin animation( $stuff ) {
  -webkit-animation: $stuff;
  -moz-animation: $stuff;
  -ms-animation: $stuff;
  animation: $stuff;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
