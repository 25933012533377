@import 'src/style/mixins';

.productsAndServices {
	h2 {
		text-align: center;
	}

	img {
		background: #EEE;
		position: relative;
		left: 50%;

		@include transform(translateX(-50%));

		margin: 20px 0 30px 0;

		border-radius: 99999px;

		overflow: hidden;
	}
	
	h3 {
		margin-bottom: 20px;

		font-size: 0.9rem;
		color: #555;
		font-weight: 700;
		letter-spacing: 0.05rem;
		text-transform: uppercase;
		text-align: center;
	}

	p {
		text-align: center;
	}
}