@import 'src/style/flexbox';
@import 'src/style/mixins';

.mast {
	background: #3c0e0e;
	min-height: 700px;
	position: relative;

	overflow: hidden;
	
	@include flexbox();
	@include align-items(center);
	@include justify-content(center);

	.overlay {
		// background: url(./mast.jpg);
		background-position: center !important;
		background-size: cover !important;
		width: calc(100% + 100px);
		height: calc(100% + 100px);
		position: absolute;
		top: -50px;
		left: -50px;

		opacity: 0.6;
	}

	img {
		width: 150%;
		height: 150%;
		position: absolute;
		right: -50%;

		@include transform(rotate(15deg));

		opacity: 0.1;
	}

	h1 {
		margin-bottom: 30px;

		font-family: 'BenchNine', sans-serif;
    text-transform: uppercase;
		font-size: 4rem;
		color: #FFF;
		font-weight: 900;
		text-align: center;
		text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
	}

	p {
		font-family: 'Open Sans', sans-serif !important;
		font-size: 1.1rem;
		color: #FFF !important;
		line-height: 1.9rem !important;
		text-align: center;
		text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	}
}

@media only screen and (max-width: 480px) {
  .mast {
		min-height: 540px;

		h1 {
			font-size: 2.5rem;	
		}

		p {
			font-size: 0.9rem;
			line-height: 1.7rem !important;
		}
  }
}