@import 'src/style/flexbox';

.qualityPolicy {
	background: #fed531;

	margin: 0 !important;
	padding: 150px 0;

	h2 {
		color: #d14b2f !important;
	}

	p {
		color: #a98b14 !important;
	}
}