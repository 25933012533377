@import url(https://fonts.googleapis.com/css?family=BenchNine:700|Open+Sans:300,700);
.nav {
  margin: 25px 0 !important;
  display: flex;
  justify-content: center; }

.mast {
  background: #3c0e0e;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center; }
  .mast .overlay {
    background-position: center !important;
    background-size: cover !important;
    width: calc(100% + 100px);
    height: calc(100% + 100px);
    position: absolute;
    top: -50px;
    left: -50px;
    opacity: 0.6; }
  .mast img {
    width: 150%;
    height: 150%;
    position: absolute;
    right: -50%;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    opacity: 0.1; }
  .mast h1 {
    margin-bottom: 30px;
    font-family: 'BenchNine', sans-serif;
    text-transform: uppercase;
    font-size: 4rem;
    color: #FFF;
    font-weight: 900;
    text-align: center;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.1); }
  .mast p {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 1.1rem;
    color: #FFF !important;
    line-height: 1.9rem !important;
    text-align: center;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.4); }

@media only screen and (max-width: 480px) {
  .mast {
    min-height: 540px; }
    .mast h1 {
      font-size: 2.5rem; }
    .mast p {
      font-size: 0.9rem;
      line-height: 1.7rem !important; } }

.about img {
  margin-bottom: 40px; }

.about h3 {
  margin: 40px 0 15px 0;
  font-size: 0.9rem;
  color: #555;
  font-weight: 700;
  letter-spacing: 0.05rem;
  text-transform: uppercase; }
  .about h3 span {
    font-size: 0.8rem;
    color: #999 !important;
    font-weight: 400 !important;
    text-transform: none !important; }

.about .caption {
  margin-bottom: 0;
  font-size: 0.9rem;
  color: #555;
  line-height: 1.5rem; }

.productsAndServices h2 {
  text-align: center; }

.productsAndServices img {
  background: #EEE;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 20px 0 30px 0;
  border-radius: 99999px;
  overflow: hidden; }

.productsAndServices h3 {
  margin-bottom: 20px;
  font-size: 0.9rem;
  color: #555;
  font-weight: 700;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  text-align: center; }

.productsAndServices p {
  text-align: center; }

.qualityPolicy {
  background: #fed531;
  margin: 0 !important;
  padding: 150px 0; }
  .qualityPolicy h2 {
    color: #d14b2f !important; }
  .qualityPolicy p {
    color: #a98b14 !important; }

.footer {
  background: #212121;
  margin: 0 !important;
  padding: 100px 0; }
  .footer p {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.7rem;
    line-height: 1.3rem !important; }
    .footer p a {
      color: #BBB !important; }
    .footer p span {
      color: #FFF;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 2rem !important; }

.app {
  font-family: 'Helvetica Neue', 'Arial', sans-serif; }
  .app h2 {
    font-family: 'BenchNine', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: 700;
    color: #555; }
  .app p {
    color: #BBB;
    line-height: 1.85rem; }
  .app .mainSection {
    margin: 150px 0; }

body {
	margin: 0;
}
